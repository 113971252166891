<!--
 * @Description:  行动宣言
 * @Author: wangying
 * @Date: 2021-09-29 09:43:02
-->
<template>
  <div class="wbody">
    <div class="title">
      <ListTitle :headerNmae="headerNmaeCh" :headerNmaeCh="headerNmae"></ListTitle>
    </div>

    <div v-show="flag" class="contentsy">
      <div v-html="content"></div>
      <div>
        <p class="fd_title">中国房地产行业绿色供应链行动董事长签署的共同宣言</p>
      </div>
      <div class="flex">
        <vue-scroll ref="flow" @handle-scroll="handleScroll" :ops="ops">

          <div class="flow">
            <div v-for="item in list" :key="item.articleId" class="prow " @click="findprowTitle(item.articleId)">
              <img class="" :src="item.imgUrl" alt="">
              <div class="prowTitle ">
                <p class="newlineOmit">{{ item.articleName }}</p>
                <p>{{ item.summary.slice(0, 3) + "&nbsp;" + item.summary.slice(3) }}</p>
              </div>
            </div>
          </div>
        </vue-scroll>

      </div>
    </div>
    <div class="finds" v-show="!flag">
      <div class="fh">
        <el-button @click="goback">返回上级</el-button>
      </div>

      <img :src="facePic" alt="">
    </div>
  </div>
</template>
<script>
import { articleDetail, articleListByPage } from '@/api'
import ListTitle from "@/components/reuse/listTitle";
import '@/css/content.less'
import vuescrolljs from '@/mixin/index.js'


export default {
  mixins: [vuescrolljs],
  components: {
    ListTitle,
  },
  data() {
    return {
      flag: true,
      headerNmae: null,
      headerNmaeCh: null,
      content: null,
      list: [],
      facePic: null,
      pages: {
        page: 0,
        limit: 9
      },
      total: 0
    }
  },

  mounted() {
    this.hub.$emit('tatleNames')
    this.hub.$emit('theCover', this.$route.meta.flagNam)

    this.hub.$emit('scrollToz')
    const params = {
      articleId: 'WZNR201610311925050032',
      isEn: this.$store.state.language === 'en' ? 1 : 0
    }
    articleDetail(params).then((res) => {
      this.content = res.data.content
      this.headerNmae = res.data.summary
      this.headerNmaeCh = res.data.articleName

    })

    this.getApiList()

  },
  methods: {
    getApiList() {
      const params1 = {
        ...this.pages,
        catCode: "000001%7C000037",
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleListByPage(params1).then((res) => {
        console.log(res.data);
        this.list = res.data.list
        this.total = res.data.total
      })
    },

    handleScroll(vertical, horizontal, nativeEvent) {
      const flowarr = this.$refs["flow"].getScrollProcess();


      if (+flowarr.v.toFixed(1) > 0.8) {
        this.pages.limit = this.pages.limit + 9
        this.getApiList()
      }


    },
    findprowTitle(id) {
      const params = {
        articleId: id,
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleDetail(params).then((res) => {
        console.log(res.data.imgUrl);
        this.facePic = res.data.imgUrl
        this.flag = false
        this.hub.$emit('scrollToz')
      })
    },
    goback() {
      this.flag = true
    }
  }
}
</script>
<style lang="less" scoped>
.wbody {
  width: 1060px;
}

.title {
  margin: 50px 0;
}

.fd_title {
  text-align: center;
  font-size: 18px;
  background: #5f7f0d;
  font-weight: 700;
  color: #fff;
}

.flex {
  height: 1000px;
  overflow: auto;

  .flow {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .prow {
    width: 266px;
    height: 368px;
    text-align: center;
    margin: 10px 0;

    img {
      width: 234px;
      height: 296px;
    }

    .prowTitle {
      margin: 0;
      background: #97c619;
      color: #fff;
      font-size: 18px;

      p {
        height: 30px;

        &:nth-child(2) {
          height: 36px;
        }
      }
    }
  }
}

.finds {
  img {
    width: 100%;
  }
}

.fh {
  position: relative;
  height: 0;
  top: -120px;
  right: 50px;
  float: right;

  button {
    border: 1px solid #5a6f18;
    color: #5a6f18;
  }
}

.newlineOmit {
  height: 30px;
}
</style>